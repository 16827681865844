import React from 'react'

export default function PreviewBooking({guestCount,bookingDetails,isModalVisible,setIsModalVisible,confirmBooking}) {
    const closeModal=()=>{
        setIsModalVisible(false)
    }
  return (
    <div
  className={`modal fade ${isModalVisible ? "show" : ""}`}
  id="exampleModal1"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  style={{ display: isModalVisible ? "block" : "none" }}
>
  <div className="modal-dialog" id="modal-dialog-preview">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">
          Preview Details
        </h1>
        <button
          type="button"
          className="btn-close"
        onClick={()=>closeModal()}
        />
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-6">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">From Date</th>
                  <td>{bookingDetails?.from_date}</td>
                </tr>
                <tr>
                  <th scope="col">To Date</th>
                  <td>{bookingDetails?.to_date}</td>
                </tr>
                <tr>
                  <th scope="col">No Of Adults</th>
                  <td>{bookingDetails?.adult}</td>
                </tr>
                <tr>
                  <th scope="col">No Of Child</th>
                  <td>{bookingDetails?.children}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Name</th>
                  <td>{bookingDetails?.name}</td>
                </tr>
                <tr>
                  <th scope="col">Phone No.</th>
                  <td>{bookingDetails?.phone}</td>
                </tr>
                <tr>
                  <th scope="col">Email Id.</th>
                  <td >{bookingDetails?.email}</td>
                </tr>
                <tr>
                  <th scope="col">Country</th>
                  <td>{bookingDetails?.country}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="traveller-informations">
          <h5>Travellers Information</h5>
        </div>
        <h5 className="preview-gurest">Guests List</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Gender</th>
              <th scope="col">Name</th>
              <th scope="col">Age</th>
            </tr>
          </thead>
          <tbody>
            {guestCount && guestCount?.length>0 && guestCount?.map((g,k)=>{
                return(
                <tr key={k}>
                    <td>{g?.gender}</td>
                    <td>{g?.name}</td>
                    <td>{g?.age} Years</td>
                </tr>
                )
            })}
            
          </tbody>
        </table>
        
      </div>
      <div className="modal-footer">
        <button onClick={()=>closeModal()} type="button" className="btn btn-danger">
          Edit
        </button>
        <button onClick={()=>confirmBooking()} type="button" className="btn btn-primary">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

  )
}
