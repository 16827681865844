import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../component/home/Home';
import About from '../component/about/About';
import IndiaTour from '../component/india-tour/IndiaTour';
import InternationalTour from '../component/international-tour/InternationalTour';
import ThemeBased from '../component/theme-based/ThemeBased';
import FixedDepartures from '../component/fixed-departures/FixedDepartures';
import Contact from '../component/contact/Contact';
import GuestFeedback from '../component/feedback/GuestFeedback';
import Payment from '../component/payment/Payment';
import PackageList from '../component/package-list/PackageList';
import PackageDetails from '../component/package-list/PackageDetails';
import Terms from '../component/others/Terms';
import PrivacyPolicy from '../component/others/PrivacyPolicy';
import FixPackageDetails from '../component/package-list/FixPackageDetails';
import Checkout from '../component/package-list/Checkout';

const AppRoutes = () => {
  return (
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/about-us" element={<About />} />
       <Route path="/india-tour" element={<IndiaTour />} />
       <Route path="/international-tour" element={<InternationalTour />} />
       <Route path="/theme-base" element={<ThemeBased />} />
       <Route path='/fixed-departures' element={<FixedDepartures/>}/> 
       <Route path='/contact-us' element={<Contact/>}/>
       <Route path='/guest-feedback' element={<GuestFeedback/>}/>  
       <Route path='/payment' element={<Payment/>}/>
       <Route path='package-list/:url' element={<PackageList/>}/>
       <Route path='package-list/filter/:destination_url/:theme_url' element={<PackageList/>}/>
       <Route path='package-list/:type/:url' element={<PackageList/>}/>
       <Route path='package-list-by-themes/:url' element={<PackageList/>}/>
       <Route path='package-list-by-themes/:type/:url' element={<PackageList/>}/> 
       <Route path='package-details/:url' element={<PackageDetails/>}/>
       <Route path='fix-package-details/:url' element={<FixPackageDetails/>}/>
       <Route path="/term-condition" element={<Terms />} />
       <Route path="/privacy-poicy" element={<PrivacyPolicy />} />  
       <Route path='/checkout/:url' element={<Checkout/>}/> 
       <Route path='/checkout/:url/:tourtype_id' element={<Checkout/>}/>  
      {/* <Route path="*" element={<NotFound/>}/> */}
    </Routes>
  );
}

export default AppRoutes;
