/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import Select from "react-select";
import Pagination from "../../elements/Pagination";
import { Link } from "react-router-dom";
export default function FixedDepartures() {
  const [Destination, setDestination] = useState([]);
  const [selectDestination, setSelectDestination] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [Themes, setThemes] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [dropdown, setDropdown] = useState({ 1: true, 2: true });
  const [banners, setBanners] = useState([]);
  const [Package, setPackage] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterPackage, setFilterPackage] = useState({});
  const [range, setRange] = useState({ left: 0, right: 150000 });
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [amens, setAmens] = useState([]);
  useEffect(() => {
    getAmenties();
    getDestinationList();
    getThemesList();
    getBannerList();
    getMeta();
  }, []);

  const getAmenties = async () => {
    ApiService.post("amen/get-data", { page: 1, limit: 10000, status: "1" })
      .then((data) => {
        if (data?.Amens) {
          setAmens(data.Amens);
        } else {
          setAmens([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "fix_departure",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getBannerList = async () => {
    ApiService.post("banner/get-data", {
      page: 1,
      limit: 6,
      status: "1",
      type: "2",
    })
      .then((data) => {
        if (data?.Banners) {
          setBanners(data.Banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getPackageList = async (filterData = {}) => {
    ApiService.post("package/get-data", {
      page: currentPage + 1,
      limit: 10,
      ...filterPackage,
      ...filterData,
      is_fix: "1",
    })
      .then((data) => {
        if (data?.Packages) {
          setPackage(data.Packages);
          setPageCount(data?.Package?.pagination?.totalCount);
        } else {
          setPackage([]);
          setPageCount(0);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handlePageClick = (selectedPage) => {
    const clickedPage = selectedPage.selected;
    if (clickedPage !== currentPage) {
      setCurrentPage(clickedPage);
    }
  };

  const changeLeft = (e, type) => {
    if (type === "left") {
      if (+e?.target?.value > +range?.right) {
        setRange({
          left: +range?.right,
          right: +range?.right,
        });
      } else {
        setRange({
          left: +e?.target?.value,
          right: +range?.right,
        });
      }
    } else {
      if (+e?.target?.value < +range?.left) {
        setRange({
          left: +range?.left,
          right: +range?.left,
        });
      } else {
        setRange({
          left: +range?.left,
          right: +e?.target?.value,
        });
      }
    }
  };
  useEffect(() => {
    getPackageList({ ...filterPackage, ...range });
  }, [range]);

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", { page: 1, limit: 100000 })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
          let dest = [];
          data.Destinations?.map((d) => {
            dest.push({ value: d?.id, label: d?.name });
          });
          setSelectDestination(dest);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", { page: 1, limit: 100000 })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
          let theme = [];
          data.Themess?.map((d) => {
            theme.push({ value: d?.id, label: d?.name });
          });
          setSelectTheme(theme);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const changeDestination = (event) => {
    const value = +event.target.value;
    const checked = event.target.checked;

    setSelectedDestination((prevSelectedItems) => {
      // If the checkbox is checked, add the value to the array
      if (checked) {
        getPackageList({
          ...filterPackage,
          destination_id: [...prevSelectedItems, +value],
        });
        return [...prevSelectedItems, +value];
      }
      // If unchecked, remove the value from the array
      else {
        getPackageList({
          ...filterPackage,
          destination_id: prevSelectedItems.filter((item) => item !== +value),
        });
        return prevSelectedItems.filter((item) => item !== +value);
      }
    });
    setFilterPackage((prevState) => {
      return { ...prevState, destination_id: selectedDestination };
    });
  };

  const changeThemes = (event) => {
    const value = +event.target.value;
    const checked = event.target.checked;

    setSelectedTheme((prevSelectedItems) => {
      // If the checkbox is checked, add the value to the array
      if (checked) {
        getPackageList({
          ...filterPackage,
          theme_id: [...prevSelectedItems, +value],
        });
        return [...prevSelectedItems, value];
      }
      // If unchecked, remove the value from the array
      else {
        getPackageList({
          ...filterPackage,
          theme_id: prevSelectedItems.filter((item) => item !== value),
        });
        return prevSelectedItems.filter((item) => item !== value);
      }
    });

    setFilterPackage((prevState) => {
      return { ...prevState, theme_id: selectedTheme };
    });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src="assets/images/banner-inner.jpg" alt="" width="100%" />
        </div>
        {/* search form start  */}
        <div className="search-form">
          <div className="search-form-box">
            <div className="form">
              <div className="form-inputs">
                <div className="search-form-input-1" id="first-select">
                  <i className="fa fa-map-marker" />
                  <Select
                    id="Cityselect"
                    options={selectDestination}
                    onChange={() => {}}
                    placeholder="Select..."
                  />
                </div>
                <div className="search-form-input-2" id="second-select">
                  <i className="fa fa-flag-o" />
                  <Select
                    id="Tourselect"
                    options={selectTheme}
                    onChange={() => {}}
                    placeholder="Select..."
                  />
                </div>
                <div className="search-form-button">
                  <button className="btn btn-search text-light">
                    Search <i className="fa fa-send-o" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* search form end  */}
      </section>
      {/* banner section end  */}
      <section className="page-titles">
        <div className="container-fluid ">
          <div className="page-head px-lg-3">
            <h1>Our Fixed Departure Packages</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              sit quia atque, rem, quis ex deserunt, accusantium reiciendis
              pariatur quam consequuntur fugit officiis rerum quae. Fuga, sint
              dignissimos. Alias, assumenda omnis. Eius, voluptates saepe, porro
              incidunt at itaque deleniti labore modi aliquid ea culpa odit
              quidem, vitae nostrum sunt impedit dolorem. Earum sapiente atque
              consequuntur optio. Doloremque facilis quaerat totam.
            </p>
          </div>
        </div>
      </section>
      <section className="details-package">
        <div className="container-fluid ">
          <div className="details-package-inner">
            <div className="row">
              <div className="col-lg-3">
                <div className="filter-button fixed-bottom d-lg-none">
                  <button id="filterBtn" className="btn btn-primary">
                    Apply Filter <i className="fa fa-filter" />
                  </button>
                </div>
                <div
                  className="filter-dropup filter-boxes-mobile "
                  id="filterDropUp"
                >
                  <div className="filter-content">
                    <button id="closeBtn" className="close-icon">
                      ×
                    </button>
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={3}>
                        <div className="dropdown-button">
                          <h5>Budget (per person)</h5>
                          <span className="arrow">
                            <i className="fa fa-angle-up" />
                          </span>
                        </div>
                        <div className="dropdown-menu open">
                          <div id="range-one" className="range-container">
                            <div className="sliders_control">
                              <input
                                type="range"
                                className="dual-range left-range"
                                min={0}
                                max={150000}
                                defaultValue={0}
                                value={range?.left}
                                step={1000}
                                onChange={(e) => changeLeft(e, "left")}
                              />
                              <input
                                type="range"
                                className="dual-range right-range"
                                min={0}
                                max={150000}
                                defaultValue={150000}
                                step={1000}
                                value={range?.right}
                                onChange={(e) => changeLeft(e, "right")}
                              />
                            </div>
                            <div className="numbers">
                              <div className="start">
                                <i className="fa fa-inr" />
                                <span>{range?.left}</span>
                              </div>
                              <div className="end">
                                <i className="fa fa-inr" />
                                <span>{range?.right}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={1}>
                        <div className="dropdown-button">
                          <h5> Cities</h5>
                          <span className="arrow">
                            <i
                              className={
                                dropdown?.[1]
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                            />
                          </span>
                        </div>
                        <div
                          className={
                            dropdown?.[1]
                              ? "dropdown-menu open"
                              : "dropdown-menu"
                          }
                        >
                          <input
                            type="text"
                            className="search-box"
                            placeholder="Search city..."
                            onChange={(e) => setCityValue(e?.target?.value)}
                          />
                          <div className="checkbox-group">
                            {Destination &&
                              Destination?.length > 0 &&
                              Destination?.filter((f) =>
                                f?.name?.includes(cityValue)
                              )?.map((d, k) => {
                                return (
                                  <div
                                    className="check-box-group-input"
                                    key={k}
                                  >
                                    <label>
                                      <input
                                        checked={selectedDestination?.includes(
                                          +d?.id
                                        )}
                                        type="checkbox"
                                        onChange={(e) => changeDestination(e)}
                                        value={d?.id}
                                      />{" "}
                                      {d?.name}
                                    </label>
                                    <span className="input-number">
                                      ({d?.packages?.[0]?.count})
                                    </span>
                                  </div>
                                );
                              })}

                            {/* <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Bangalore
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={2}>
                        <div className="dropdown-button">
                          <h5>Themes</h5>
                          <span className="arrow">
                            <i
                              className={
                                dropdown?.[2]
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                            />
                          </span>
                        </div>
                        <div
                          className={
                            dropdown?.[2]
                              ? "dropdown-menu open"
                              : "dropdown-menu"
                          }
                        >
                          <div className="checkbox-group">
                            {Themes &&
                              Themes?.length > 0 &&
                              Themes?.map((d, k) => {
                                return (
                                  <div
                                    className="check-box-group-input"
                                    key={k}
                                  >
                                    <label>
                                      <input
                                        onChange={(e) => changeThemes(e)}
                                        type="checkbox"
                                        defaultValue=""
                                        value={d?.id}
                                        checked={selectedTheme?.includes(d?.id)}
                                      />{" "}
                                      {d?.name}
                                    </label>
                                    <span className="input-number">
                                      ({d?.packages?.[0]?.count})
                                    </span>
                                  </div>
                                );
                              })}
                            {/*                         
                        <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Wildlife
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offer-img">
                      <img
                        src="assets/images/inner-page-offer.png"
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {Package &&
                  Package?.length > 0 &&
                  Package?.map((p, k) => {
                    return (
                      <>
                        <div className="inner-page-box">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="inner-packages-image">
                                {p?.promotion === "1" && (
                                  <div className="promotion-tag">
                                    <span>Promotion</span>
                                  </div>
                                )}
                                <img
                                  src={`${Image_PATH}Package/${p?.image_url}`}
                                  alt=""
                                  width="100%"
                                />
                                <div className="inner-package-img-overlay">
                                  <h6>{p?.theme?.name}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="package-incluision-box">
                                <div className="inner-package-date">
                                  <span>{p?.duration}</span>
                                </div>
                                <div className="inner-package-name">
                                  <h4>{p?.title}</h4>
                                </div>
                                <div className="inner-package-dest">
                                  <p>
                                    <b> Destination: </b> Arrive
                                    {p?.destination_cover}{" "}
                                  </p>
                                </div>
                                <div className="inner-package-rating">
                                  <span>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star half-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    ({p?.rating_count} reviews)
                                  </span>
                                </div>
                                <div className="package-itenary">
                                  {JSON.parse(p?.amenties_id)?.map((pi, k1) => {
                                    return (
                                      <div className="itneary-box" key={k1}>
                                        <div className="itneray-img">
                                          <img
                                            alt={
                                              amens?.filter(
                                                (am) => +am?.id === +pi
                                              )?.[0]?.title
                                            }
                                            src={`${Image_PATH}Amen/${
                                              amens?.filter(
                                                (am) => +am?.id === +pi
                                              )?.[0]?.image_url
                                            }`}
                                          />
                                          <div className="itneray-name">
                                            <span>
                                              {
                                                amens?.filter(
                                                  (am) => +am?.id === +pi
                                                )?.[0]?.title
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="package-pricing-box">
                                <div className="del-price">
                                  <del>
                                    {" "}
                                    <h4>
                                      <i className="fa fa-inr" />{" "}
                                      {p?.price || 0}
                                    </h4>{" "}
                                  </del>
                                </div>
                                <div className="main-price">
                                  <h4>
                                    <i className="fa fa-inr" />{" "}
                                    {p?.offer_price || 0}
                                  </h4>
                                </div>
                                <div className="price-para">
                                  <span> Per Person On Twin Sharing</span>
                                </div>
                                <div className="buttons-group">
                                  <div className="deatails-btn">
                                    <Link
                                      to={`/fix-package-details/${p?.url}`}
                                      className="btn btn-details"
                                    >
                                      {" "}
                                      View Details
                                    </Link>
                                    <Link
                                      to={`/checkout/${p?.url}`}
                                      className="btn btn-enquiry"
                                    >
                                      {" "}
                                      Book Now
                                    </Link>
                                  </div>
                                  <div className="contact-btn">
                                    <Link
                                      to={`tel:${p?.phone}`}
                                      className="btn btn-call"
                                    >
                                      {" "}
                                      Call
                                    </Link>
                                    <Link
                                      to={`https://api.whatsapp.com/send?phone=${p?.whatsapp}&text=hello`}
                                      className="btn btn-whatsapp"
                                    >
                                      {" "}
                                      Whatsapp
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}

                <div className="pagignation-box">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
