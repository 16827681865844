/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function ThemeBased() {
  const [Themes, setThemes] = useState([]);
  const [Destination, setDestination] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  useEffect(() => {
    getThemesList();
    getDestinationList();
    getMeta();
  }, []);

  const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    dots: true,
    nav: true,
    autoplayTimeout: 3000,
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "theme_based",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", { page: 1, limit: 1000 })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", { page: 1, limit: 10000 })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src="assets/images/theme-banner.jpg" alt="" width="100%" />
        </div>
      </section>
      {/* banner section end  */}
      {/* special travels area start  */}
      <section className="special-travels pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Travel Specialization</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row">
                {Themes &&
                  Themes?.length > 0 &&
                  Themes?.map((th, k) => {
                    let content = [];

                    // A for loop to create multiple elements for each item
                    for (let i = 0; i < +th?.star_rating; i++) {
                      content.push(<i className="fa fa-star" />);
                    }

                    return (
                      <div className="col-lg-4 col-md-6 col-12 mb-3" key={k}>
                        <div className="special-packages">
                          <Link to={`/package-list-by-themes/${th?.url}`}>
                            <div className="special-images">
                              <img
                                src={`${Image_PATH}Themes/${th?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="special-text">
                              <div className="special-text-name">
                                <h6>{th?.name}</h6>
                                <p>
                                  {th?.packages?.[0]?.count || 0} Tour Packages
                                </p>
                              </div>
                              <div className="special-text-review">
                                <span>{content}</span>
                                <p>{th?.total_review} Review</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/1.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Honeymoon Tour</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Leasure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Trekking</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Adventure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/5.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Hill Station</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/6.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Yoga</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area end  */}
      {/*international destination area start  */}
      <section className="destination-international">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Best Tour Destination</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="intern-boxes mt-5">
            <div className="slider">
              {Destination && Destination?.length > 0 && (
                <OwlCarousel
                  options={options}
                  className="owl-carousel owl-theme"
                >
                  {Destination &&
                    Destination?.length > 0 &&
                    Destination?.map((dt, k) => {
                      return (
                        <div className="item" key={k}>
                          <div className="intern-box">
                            <Link to={`/package-list/${dt?.url}`}>
                              <div className="destination-img">
                                <img
                                  src={`${Image_PATH}destination/${dt?.image_url}`}
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <div className="inter-text">
                                <h5> {dt?.name} </h5>
                                <p>
                                  {dt?.packages?.[0]?.count || 0} Tour Package
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/uttarakhand.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Uttarakhand Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/goa.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5>Goa Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/rajasthan.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Rajasthan Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/kashmir.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Kashmir Tour</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/kerala.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Kerala Tour</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/tamil.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Tamil Nadu</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div> */}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* destination area start  */}
    </>
  );
}
