import React from 'react'

export default function Terms() {
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/terms-banner.jpg" alt="" width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  <div className="term-condition-sec py-5">
    <div className="container-fluid px-lg-5">
      <div className="title">
        <h2>Terms &amp; Conditions</h2>
      </div>
      <div className="terms-data mt-3">
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>PACKAGE EXCLUSIONS : -</strong>
          </span>
        </p>
        <ul>
          <li>Anything not mentioned under Package Inclusions.</li>
          <li>
            Air Fare / Train fare/Helicopter Ticket / Porter, pony, horse, cable
            car, boat or Adventure Activity if not mentioned in Inclusion .
          </li>
          <li>A/C Will Not Work In Hill Area.</li>
          <li>
            Personal Expenses such as Laundry, telephone calls, tips &amp;
            gratuity, mineral water, soft &amp; hard drinks, rafting, rock
            climbing, paragliding, joy ride (Toy Train), Porterage.
          </li>
          <li>
            Additional sightseeing or extra usage of vehicle, other than
            mentioned in the itinerary You have to Pay Extra .
          </li>
          <li>
            Any Cost arising due to natural calamities like, landslides, road
            blockage, political disturbances (strikes), etc (to be borne by the
            client, which is directly payable on the spot).
          </li>
          <li>
            Any increase in taxes or fuel price, if it will increase you have to
            pay directly whatever will be extra amount.
          </li>
          <li>
            If you Need Room Heater you have to pay to the hotel directly it's
            Not included in the package cost
          </li>
          <li>
            Rohtang pass Charge Extra if it is Not Included In the Inclusion.
          </li>
          <li>All personal expenses, optional tours and extra meals.</li>
          <li>
            Any kind of entry fees &amp; Guide charges anywhere if not mentioned
            in Inclusion.
          </li>
          <li>
            Tips ,Travel Insurance, medical insurance, laundry charges, liquors,
            mineral water, telephone charges.
          </li>
          <li>
            If any Miss happening During Travel like Road Accident or any
            Physical Injury company is not responsible in That Case if you need
            security you can take Travel Insurance cost will be extra for Travel
            Insurance
          </li>
          <li>All items of personal nature.</li>
          <li>No Hidden Cost</li>
          <li>05% GST</li>
        </ul>
        <p>
          <br />
          <span style={{ color: "#ff6600" }}>
            <strong>CANCELLATION POLICY : -</strong>
          </span>
        </p>
        <ul>
          <li>
            20% Charge of the total Package booking on amendment/Plan Change.
          </li>
          <li>
            30% cancellation Charge of the package cost after
            confirmation/issuing the hotel voucher.
          </li>
          <li>
            Cancellations made 45 days before departure Chargeable 30% of the
            Package Cost
          </li>
          <li>
            Cancellations made 35 to 44 days before departure 40% Chargeable of
            the Package Cost
          </li>
          <li>
            Cancellations made 22 to 34 days before departure 50% Chargeable of
            the Package Cost
          </li>
          <li>
            100% Chargeable of the package cost 14 to 21 days before departure
          </li>
          <li>No. flexibility at the time of Travel in the plan.</li>
          <li>
            Any train and flight tickets cancellation will be applicable as per
            Indian railway / airline rules and refund/adjustment can be given
            only after receiving the refund from train or airline company.
          </li>
          <li>
            No cancellation &amp; any refund will be made at any cost in case of
            bad weather or landslide and natural digester, or terrorism and
            which is beyond the control of the age. we will heartly help you in
            that situation which is possible from our side .
          </li>
          <li>
            Cancellation &amp; other policies as per the hotel/company rule.
          </li>
          <li>All cancellation are to be communicated in written by mail.</li>
          <li>
            Any amendment in the original booking u have to update in written by
            mail.
          </li>
          <li>
            There are no refund due to any pandemic or virous attack in that
            situation we can amend your travel date for the future booking .
          </li>
        </ul>
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>INSURANCE&nbsp;: -</strong>
          </span>
        </p>
        <p>
          Travel Insurance only Offered on your Request it's covered by third
          Party.
          <br />
          Cancellation chargeable will be applicable as per the policy.
          <br />
          REFUND AFTER CANCELLATION
        </p>
        <p>
          The Refund after cancellation of the booking will be take 14 working
          days from the cancellation date. if you cancelling any
          Railway/Airline/Volvo ticket's all refund will be procced after
          receiving by the respective Airline/service provider
        </p>
        <p>
          <br />
          <span style={{ color: "#ff6600" }}>
            <strong>NOTE :-</strong>
          </span>
        </p>
        <p>
          Due to Heavy snowfall if Given cab is unable to Reach Solang Valley in
          that situation we will go till that point which is easily reachable
          called that point Snow Point . if 4x4 Jeep are going to solang valley
          you have to pay extra amount to the cab directly. Because we are
          charging till Snow Point. and you will go by own risk to Solang Valley
          company are not responsible
        </p>
        <ul>
          <li>Hotels are subject to availability at time of reservation.</li>
          <li>
            If the base category room will be not available then supplement cost
            will be Applicable for upper Category rooms.
          </li>
          <li>
            All State tax as per the current situation if any change in state
            tax you have to pay directly whatever will be additional amount .
          </li>
        </ul>
        <p>
          All rates offering from Minto subject to change without any prior
          update. All Rates subject to availability
          <br />
          Trains/Flight/Volvo ticket Subject to availability
          <br />
          All quoted flight Tickets rates are flexible can be change at the time
          of booking.
        </p>
      </div>
    </div>
  </div>
  {/* Modal */}
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header" id="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close modal-button-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="row">
          <div className="col-lg-6" id="mobile-modal-hide">
            <div className="modal-body">
              <div className="modal-banner-content">
                <div className="modal-banner">
                  <div
                    id="carouselExampleRide"
                    className="carousel slide carousel-fade"
                    data-bs-ride="true"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/1.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/2.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/3.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <div className="modal-features">
                  <div className="why-features row py-3">
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/traveler.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Happy Traveler</span>
                      </div>
                    </div>
                    <div
                      className="features-box col-4"
                      id="features-box-border"
                    >
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/loyalty.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Tours Success</span>
                      </div>
                    </div>
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/experience.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Positives Review </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="last-line">
                    <p className="text-center">
                      <b>Excellent!</b>{" "}
                      <span>
                        {" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle-o" />{" "}
                      </span>{" "}
                      4.5 Rating Out of 5.0 Based On 526 reviews.
                    </p>
                  </div>
                </div>
                <div className="modal-contact">
                  <div className="call-modal">
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-phone" /> Call Us :{" "}
                        </span>{" "}
                        +91-1234567890
                      </a>
                    </h6>
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-envelope" /> Send Us Mail :{" "}
                        </span>{" "}
                        info@mintoholidays.com
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6" id="pop-right">
            <div className="modal-body" id="right-body">
              <div className="popup-form">
                <h5>Get in touch with us</h5>
                <form action="">
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Enter Phone no."
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Id."
                    />
                  </div>
                  <div className="col mb-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Enter Tour Details"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col- mb-3">
                    <button className="btn btn-submit">Submit Enquiry</button>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                  </div>
                </form>
              </div>
              <div className="get-whatsapp">
                <a href="">
                  <span>
                    <img src="assets/images/icons/whatsapp.png" alt="" />
                  </span>
                  <h6>Get itenary On Whatsapp</h6>
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}
