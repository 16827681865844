import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/banner-about.jpg" alt="" width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  <div className="term-condition-sec py-5">
    <div className="container-fluid px-lg-5">
      <div className="title">
        <h2>Privacy Policy</h2>
      </div>
      <div className="terms-data mt-3">
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>Effective Date:</strong>
          </span>{" "}
          [06-09-2024]
        </p>
        <p>
          Welcome to mintoholidays.com. At{" "}
          <span style={{ color: "#ff6600" }}>
            <strong>Minto Holidays</strong>
          </span>
          , we value your privacy and are committed to protecting your personal
          information. This Privacy Policy outlines how we collect, use, share,
          and protect your information when you use our website.
        </p>
        <h5>
          <strong>1. Information We Collect</strong>
        </h5>
        <ul>
          <li>
            <span style={{ color: "#ff6600" }}>
              <strong>Personal Information:</strong>
            </span>{" "}
            When you book a tour, subscribe to our newsletter, or contact us, we
            may collect personal information such as your name, email address,
            phone number, and payment details.
          </li>
          <li>
            <span style={{ color: "#ff6600" }}>
              <strong>Non-Personal Information:</strong>
            </span>{" "}
            We may collect non-personal information like your browser type, IP
            address, and browsing behavior through cookies and similar
            technologies.
          </li>
        </ul>
        <h5>
          <strong>2. How We Use Your Information</strong>
        </h5>
        <p>We use your information to:</p>
        <ul>
          <li>Process and manage your bookings and payments.</li>
          <li>
            Send you booking confirmations, updates, and customer service
            responses.
          </li>
          <li>Improve our website, services, and customer experience.</li>
          <li>
            Send promotional offers, newsletters, and other communications (you
            may opt out at any time).
          </li>
          <li>Comply with legal obligations and protect our rights.</li>
        </ul>
        <h5>
          <strong>3. Sharing Your Information</strong>
        </h5>
        <p>We may share your information with:</p>
        <ul>
          <li>
            <span style={{ color: "#ff6600" }}>
              <strong>Service Providers:</strong>
            </span>{" "}
            Third-party vendors and partners who assist in providing our
            services, such as payment processors, travel suppliers, and
            marketing agencies.
          </li>
          <li>
            <span style={{ color: "#ff6600" }}>
              <strong>Legal Authorities:</strong>
            </span>{" "}
            To comply with applicable laws, regulations, or legal requests, or
            to protect our rights and safety.
          </li>
          <li>
            <span style={{ color: "#ff6600" }}>
              <strong>Business Transfers:</strong>
            </span>{" "}
            In case of a merger, acquisition, or sale of assets, your
            information may be transferred as part of the business transaction.
          </li>
        </ul>
        <h5>
          <strong>4. Cookies and Tracking Technologies</strong>
        </h5>
        <p>
          Our website uses cookies and similar technologies to enhance your
          browsing experience, analyze website traffic, and deliver personalized
          content. You can manage your cookie preferences through your browser
          settings.
        </p>
        <h5>
          <strong>5. Your Rights</strong>
        </h5>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal information.</li>
          <li>Opt out of receiving promotional communications from us.</li>
          <li>
            Request the restriction or objection of processing your personal
            data.
          </li>
        </ul>
        <p>
          To exercise your rights, please contact us at [insert email address].
        </p>
        <h5>
          <strong>6. Data Security</strong>
        </h5>
        <p>
          We implement appropriate technical and organizational measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction. However, no online data
          transmission or storage system can be guaranteed to be 100% secure.
        </p>
        <h5>
          <strong>7. Third-Party Links</strong>
        </h5>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these websites. We
          encourage you to review their privacy policies before sharing any
          personal information.
        </p>
        <h5>
          <strong>8. Changes to This Privacy Policy</strong>
        </h5>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal obligations. Any changes will be posted on
          this page with the updated effective date.
        </p>
        <h5>
          <strong>9. Contact Us</strong>
        </h5>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>Minto Holidays</strong>
          </span>
          <br />
          Email: [enquiry@mintoholidays.com]
          <br />
          Phone: [+91-123467890]
          <br />
          Website:{" "}
          <a href="http://mintoholidays.com" target="_new" rel="noopener">
            mintoholidays.com
          </a>
        </p>
      </div>
    </div>
  </div>
</>

  )
}
