/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactMarkdown from 'react-markdown';
export default function PackageDetails() {
  let initialEnq = {
    name: "",
    email: "",
    phone: "",
    city_name: "",
    comment: "",
  };
  const [enquiryData, setEnquiryData] = useState(initialEnq);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const { url } = useParams();
  const [packages, setPackages] = useState({});
  const [similarPackage, setSimilarPackage] = useState([]);
  const [amens,setAmens]=useState([]);
 useEffect(()=>{
    getAmenties();
    if(url) getPackageById();
  },[url])

  const getAmenties=async ()=>{
    ApiService.post('amen/get-data',{page:1,limit:10000,status:'1'}).then((data)=>{
      if(data?.Amens){
        
        setAmens(data.Amens);
      }else{
        setAmens([]);
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }
  
  useEffect(() => {
    if (packages && Object.keys(packages)?.length) {
      getPackageList(packages);
    }
  }, [packages]);

  const getPackageById = async () => {
    ApiService.get(`package/package-details/${url}`)
      .then((data) => {
        if (data?.data) {
          let packageDataPrice=data?.data?.package_prices?.filter((pp)=> data?.data?.offer_price === pp?.offer_price)
          data['data']['tourtype_id']=packageDataPrice?.[0]?.Tourtype?.id
          setPackages(data?.data);
          setPage(data?.data);
          setMetaTags(data?.data?.header);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const scrollToDiv = (target) => {
    const targetDiv = document.getElementById(`${target}`);
    targetDiv.scrollIntoView({ behavior: "smooth" });
  };

  const changeInput = async (e, name) => {
    setEnquiryData((prevState) => {
      return {
        ...prevState,
        [name]: e?.target?.value,
      };
    });
  };

  const submitEnquiry = () => {
    if (!enquiryData?.email || !validateEmail(enquiryData?.email)) {
      toast.error("Email Not Valid");
      return;
    }
    if (!enquiryData?.phone || !validateMobileNumber(enquiryData?.phone)) {
      toast.error("Mobile Not Valid");
      return;
    }
    ApiService.post("enquiry", enquiryData)
      .then((data) => {
        if (data?.message) {
          toast.success(data?.message);
          setEnquiryData(initialEnq);
        } else {
          toast.success("Something Went Worng");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    // Regular expression for exactly 10 digits
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  const getPackageList = async (filterData = {}) => {
    ApiService.post("package/get-data", {
      page: 1,
      limit: 15,
      type: filterData?.type,
      theme_id: [+filterData?.theme_id],
      destination_id: [+filterData?.destination_id],
      id: filterData?.id,
    })
      .then((data) => {
        if (data?.Packages) {
          setSimilarPackage(data.Packages);
        } else {
          setSimilarPackage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const changeTourType = (e) => {
    let price = packages?.package_prices?.filter(
      (p) => +p?.id === +e?.target?.value
    );
    setPackages((prev) => {
      return {
        ...prev,
        offer_price: price?.[0]?.offer_price || 0,
        price: price?.[0]?.price || 0,
        tourtype_id:+e?.target?.value
      };
    });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src="assets/images/banner-inner.jpg" alt="" width="100%" />
        </div>
        {/* search form start  */}
        {/* <div class="search-form">
    <div class="search-form-box">
    <form action="">
  <div class="form-inputs">
    <div class="search-form-input-1">
    <select name="" id="">
  <option> Select city..</option>
    </select>
    </div>
    <div class="search-form-input-2">
  <select name="" id="">
      <option> Select Your Package..</option>
  </select>
  </div>
  <div class="search-form-button">
      <button class="btn btn-search text-light">Search</button>
      </div>
  </div>
    </form>
</div>
   </div> */}
        {/* search form end  */}
      </section>
      {/* banner section end  */}
      <section className="package-details pt-5 pb-4">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="details-page-inner">
                <div className="tour-duration">
                  <span>{packages?.duration || ""}</span>
                </div>
                <div className="package-details-name">
                  <h3>{packages?.title || ""}</h3>
                </div>
                <div className="inner-package-rating">
                  <span className="rating-inner">
                    4.5 * Reviews
                    <div className="inner-star" style={{ paddingLeft: 5 }}>
                      <img src="assets/images/icons/star.svg" alt="" />
                    </div>
                    <div className="inner-star">
                      <img src="assets/images/icons/star.svg" alt="" />
                    </div>
                    <div className="inner-star">
                      <img src="assets/images/icons/star.svg" alt="" />
                    </div>
                    <div className="inner-star">
                      <img src="assets/images/icons/star.svg" alt="" />
                    </div>
                    <div className="inner-star half-star">
                      <img src="assets/images/icons/star.svg" alt="" />
                    </div>
                    <a href=""> ({packages?.rating_count} reviews)</a>
                  </span>
                </div>
                <div className="destination-inner">
                  <p>
                    <span>
                      <i className="fa fa-map-marker" />
                    </span>{" "}
                    {packages?.destination_cover || ""}
                  </p>
                </div>
                <div className="incluison-btn">
                  <div className="package-itenary">
                    {packages?.amenties_id &&
                      JSON.parse(packages?.amenties_id)?.map((pi, k1) => {
                        return (
                          <div className="itneary-box" key={k1}>
                            <div className="itneray-img">
                              <img
                                alt={
                                  amens?.filter((am) => +am?.id === +pi)?.[0]
                                    ?.title
                                }
                                src={`${Image_PATH}Amen/${
                                  amens?.filter((am) => +am?.id === +pi)?.[0]
                                    ?.image_url
                                }`}
                              />
                              <div className="itneray-name">
                                <span>
                                  {
                                    amens?.filter((am) => +am?.id === +pi)?.[0]
                                      ?.title
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="inner-page-btn">
                    <Link
                      to={`tel:${packages?.phone}`}
                      className="btn btn-call"
                    >
                      <i className="fa fa-phone" /> Call
                    </Link>
                    <Link
                      to={`https://api.whatsapp.com/send?phone=${packages?.whatsapp}&text=hello`}
                      className="btn btn-whatsapp"
                    >
                      <i className="fa fa-whatsapp" /> Whatsapp
                    </Link>
                    <Link
                      to={`/checkout/${packages?.url}/${packages?.tourtype_id}`}
                      className="btn btn-enquiry"
                    >
                      {" "}
                      Book Now
                    </Link>
                  </div>
                </div>
                <div id="inner-banner">
                  <div className="slider mt-3">
                    {packages?.package_images?.filter((pk) => pk?.type === "0")
                      ?.length > 0 && (
                      <OwlCarousel
                        options={options}
                        className="owl-carousel owl-theme"
                        id="owl-banner"
                      >
                        {packages?.package_images
                          ?.filter((pk) => pk?.type === "0")
                          ?.map((pi, k1) => {
                            return (
                              <div className="item" key={k1}>
                                <img
                                  src={`${Image_PATH}Package/${pi?.image_url}`}
                                  className="d-block w-100"
                                  alt="..."
                                />
                              </div>
                            );
                          })}
                      </OwlCarousel>
                    )}
                  </div>
                </div>
                {/* New Sticky Navbar */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="left-side-content">
                <div className="details-price-box">
                  <div className="main-prices">
                    <p className="mb-0">Starting from</p>
                    <h4>
                      <i className="fa fa-inr" />
                      {packages?.offer_price || 0}/- <span>Per Person</span>
                    </h4>
                    <div className="del-price">
                      <h6>
                        <del>
                          <i className="fa fa-inr" />
                          {packages?.price || 0}/-
                        </del>
                      </h6>
                    </div>
                  </div>
                  <div className="more-details-btn">
                    <div className="select-tour-type">
                      <select
                        className="tour-type"
                        onChange={(e) => changeTourType(e)}
                      >
                        <option value={""}>Select Tour Type</option>
                        {packages?.package_prices &&
                          packages?.package_prices?.map((pp, k) => {
                            return (
                              <option
                                selected={
                                  packages?.offer_price === pp?.offer_price
                                }
                                key={k}
                                value={pp?.id}
                              >
                                {pp?.Tourtype?.name}
                              </option>
                            );
                          })}
                        {/* <option value="Deluxe Tour">Deluxe Tour</option>
                    <option value="Deluxe Tour">Luxury Tour</option> */}
                      </select>
                    </div>
                    <Link style={{"color": "#fff",
    "background-color": "#f5721c",
    "padding": "8px",
    "width": "100%",
    "font-weight": "600",
    "margin-top": "10px"}}
                      to={`/checkout/${packages?.url}/${packages?.tourtype_id}`}
                      className="btn btn-more-detaisl"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
                <div className="inner-page-gallery mt-3">
                  <div className="row">
                    {packages?.package_images
                      ?.filter((pk) => pk?.type === "0")
                      ?.slice(0, 4)
                      ?.map((pi, k1) => {
                        return (
                          <div className="col-lg-6 col-3" key={k1}>
                            <div className="gallery-img">
                              <img
                                src={`${Image_PATH}Package/${pi?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </div>
                        );
                      })}
                    {/* <div className="col-lg-6 col-3">
                  <div className="gallery-img">
                    <img
                      src="assets/images/popular-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-3">
                  <div className="gallery-img">
                    <img
                      src="assets/images/popular-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div> */}
                    {/* <div className="col-lg-6 col-3">
                  <div className="gallery-img">
                    <img
                      src="assets/images/popular-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="more-images-btn">
                      <a className="btn btn-more-detaisl">View More Images</a>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sticky-header mb-5">
        {/* New Sticky Navbar */}
        <nav id="scroll-navbar" className="navbar navbar-expand navbar-light">
          <div className="container-fluid">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <button
                  onClick={() => scrollToDiv("itinerary")}
                  className="nav-link active"
                  to="#itinerary"
                >
                  Itinerary
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToDiv("tour-information")}
                  className="nav-link"
                  href="#tour-information"
                >
                  Tour Information
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToDiv("tour-details")}
                  className="nav-link"
                  href="#tour-details"
                >
                  Tour Details
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Sections */}
            <section id="itinerary">
              <div className="itinerary-section">
                <div className="itinerary-title mb-4 pb-3">
                  <h4>Our Tour Itinerary</h4>
                </div>
                <div className="days-box">
                  {/* Day 1 Itinerary */}
                  {packages?.package_itenaries &&
                    packages?.package_itenaries?.length > 0 &&
                    packages?.package_itenaries
                      ?.sort((a, b) => a?.day - b?.day)
                      ?.map((pi, k) => {
                        return (
                          <div id={`day${k + 1}`} className="itinerary-item">
                            <div
                              className="itinerary-header"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseDay1"
                              aria-expanded="true"
                              aria-controls="collapseDay1"
                              onclick="setActive(this)"
                            >
                              <div className="icon-circle">
                                <i className="fa fa-map-marker" />
                              </div>
                              <div className="day-box">
                                <span> Day {pi?.day}</span>
                              </div>{" "}
                              {pi?.title || ""}
                              <span className="toggle-icon">-</span>
                            </div>
                            <div
                              id={`collapseDay${k + 1}`}
                              className="collapse show itinerary-content"
                            >
                              <p>
                                                    <ReactMarkdown>{pi?.description}</ReactMarkdown>
                                                   {/* {pi?.description || ""} */}
                                                  </p>
                            </div>
                          </div>
                        );
                      })}
                </div>
                {/* Repeat for Days 3, 4, and 5 */}
                {/* ... */}
              </div>
            </section>
            <section id="tour-information">
              <div className="itinerary-section">
                <div className="itinerary-title mb-4 pb-3">
                  <h4>Our Tour Information</h4>
                </div>
                <div className="tour-information-content">
                  <div className="tabs-sec">
                    {/* Tabs Navigation */}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#incluiosn-box"
                          role="tab"
                          aria-controls="incluiosn-box"
                          aria-selected="true"
                        >
                          Inclusion
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#excluiosn-box"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Exclusion
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content mt-5" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="incluiosn-box"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="inclusions-box">
                        <div className="inclusions-box-title pb-3">
                          <span> package Inclusion :</span>
                        </div>
                        <ul>
                          {packages &&
                            packages?.inclusion &&
                            packages?.inclusion?.split("##").map((ex, k) => {
                              return <li key={k}>{ex}</li>;
                            })}

                          {/* <li>
                        To and fro economy class air travel for ‘Mumbai to
                        Mumbai Tour’ guests as mentioned in the itinerary
                      </li>
                      <li>Airfare, Airport taxes and Visa Fees</li>
                      <li>Baggage Allowance as per the airline policy</li>
                      <li>Tour Manager Services throughout the tour</li>
                      <li>
                        Travel by comfortable A/C coach as per the tour
                        itinerary
                      </li>
                      <li>
                        Entrance fees of all sightseeing places to be visited
                        from inside
                      </li>
                      <li>
                        Accommodation in comfortable and convenient hotels on
                        twin sharing basis
                      </li>
                      <li>
                        All Meals – Breakfast, Lunch, Dinner (set menu) as
                        mentioned in the itinerary
                      </li> */}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="excluiosn-box"
                      role="tabpanel"
                      aria-labelledby="excluiosn-box"
                    >
                      <div className="exclusion-box">
                        <div className="exclusion-box-title pb-3">
                          <span> package Exclusion :</span>
                        </div>
                        <ul>
                          {packages &&
                            packages?.exclusion &&
                            packages?.exclusion?.split("##").map((ex, k) => {
                              return <li key={k}>{ex}</li>;
                            })}
                          {/* <li>
                        To and fro economy class air travel for ‘Mumbai to
                        Mumbai Tour’ guests as mentioned in the itinerary
                      </li>
                      <li>Airfare, Airport taxes and Visa Fees</li>
                      <li>Baggage Allowance as per the airline policy</li>
                      <li>Tour Manager Services throughout the tour</li>
                      <li>
                        Travel by comfortable A/C coach as per the tour
                        itinerary
                      </li>
                      <li>
                        Entrance fees of all sightseeing places to be visited
                        from inside
                      </li>
                      <li>
                        Accommodation in comfortable and convenient hotels on
                        twin sharing basis
                      </li>
                      <li>
                        All Meals – Breakfast, Lunch, Dinner (set menu) as
                        mentioned in the itinerary
                      </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="tour-details">
              <div className=" itinerary-section">
                <div className="itinerary-title mb-4 pb-3">
                  <h4>Our Tour Details</h4>
                </div>
                <div className="tour-details-content">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: packages?.tour_details,
                      }}
                    />
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-4">
            <div className="details-left-side-fixed sticky-custom">
              <div className="contat-icons-fixed mb-2">
                <Link
                  to={`tel:${packages?.phone}`}
                  className="details-call-icon-fxed"
                >
                  <i className="fa fa-phone" /> <p>Contact us</p>
                </Link>
                <Link
                  target="_blank"
                  to={`https://api.whatsapp.com/send?phone=${packages?.whatsapp}&text=hello`}
                  className="details-whatsapp-icon-fxed"
                >
                  <i className="fa fa-whatsapp" /> <p>Whatsapp Us</p>
                </Link>
              </div>
              <div className="contat-icons-fixed mb-5">
                <Link
                  to={`mailto:${packages?.email}`}
                  className="details-email-icon-fxed"
                >
                  <i className="fa fa-envelope-o" /> <p>Send Us Mail</p>
                </Link>
                <Link
                  to={`/checkout/${packages?.url}/${packages?.tourtype_id}`}
                  className="details-enquiry-icon-fxed"
                >
                  <i className="fa fa-hand-pointer-o" /> <p>Book Now</p>
                </Link>
              </div>
              <div className="details-page-form">
                <div className="form-upper-title">
                  <h6>Explore Royal Rajasthan Tour</h6>
                </div>
                <form className="mt-3" action="">
                  <div className="row">
                    <div className="mb-3 col-12">
                      <input
                        value={enquiryData?.name}
                        type="text"
                        className="form-control"
                        id="Name"
                        placeholder="Enter Full Name *"
                        onChange={(e) => changeInput(e, "name")}
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <input
                        value={enquiryData?.phone}
                        type="tel"
                        className="form-control"
                        id="Phone"
                        placeholder="Enter Phone No. *"
                        onChange={(e) => changeInput(e, "phone")}
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <input
                        value={enquiryData?.email}
                        type="email"
                        className="form-control"
                        id="Email"
                        placeholder="Enter Email Id *"
                        onChange={(e) => changeInput(e, "email")}
                      />
                    </div>
                  </div>
                  <div className="mb-3 col-12">
                    <textarea
                      value={enquiryData?.comment}
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style={{ height: 100 }}
                      defaultValue={""}
                      onChange={(e) => changeInput(e, "comment")}
                    />
                  </div>
                  <div className="more-details-btn">
                    <button
                      type="button"
                      onClick={() => submitEnquiry()}
                      className="btn btn-more-detaisl"
                    >
                      Submit Your Enquiry
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popular tour area start  */}
      <section className="popular-tour pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Related Tours</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row pt-5">
            {similarPackage &&
              similarPackage?.length > 0 &&
              similarPackage?.map((si, k) => {
                return (
                  <div className="col-lg-4 col-12 mb-3" key={k}>
                    <Link to={`/package-details/${si?.url}`}>
                      <div className="popular-packages py-2 ">
                        <div className="row">
                          <div className="col-lg-12 col-md-6 col-12">
                            <div className="row popular-packages-box m-1">
                              <div className="col-4">
                                <div className="popular-package-img">
                                  <img
                                    src={`${Image_PATH}Package/${si?.image_url}`}
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="popular-package-text">
                                  <h5>{si?.title}</h5>
                                  <span>{si?.duration}</span>
                                  <p>{si?.destination_cover}</p>
                                  <h4>
                                    <i className="fa fa-inr" />{" "}
                                    {si?.offer_price || 0}/-{" "}
                                    <span>Per Person</span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            {/* <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/5.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/9.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> */}
          </div>
        </div>
      </section>
      {/* popular tour area end  */}
    </>
  );
}
