/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactMarkdown from 'react-markdown';
export default function About() {
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [testimonial, setTestimonial] = useState([]);
  const [whyBook, setWhyBook] = useState([]);
  const [settingData, setSettingsData] = useState({});
  useEffect(() => {
    getTestimonialList();
    getWhyBookList();
    getSettings();
    getMeta();
  }, []);

  const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    dots: true,
    nav: true,
    autoplayTimeout: 3000,
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "about",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getTestimonialList = async () => {
    ApiService.post("testimonial/get-data", { page: 1, limit: 50, status: "1" })
      .then((data) => {
        if (data?.testimonials) {
          console.log(data?.testimonials);
          setTestimonial(data.testimonials);
        } else {
          setTestimonial([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getWhyBookList = async () => {
    ApiService.post("whybook/get-data", { page: 1, limit: 4, status: "1" })
      .then((data) => {
        if (data?.WhyBooks) {
          setWhyBook(data.WhyBooks);
        } else {
          setWhyBook([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getSettings = async () => {
    ApiService.get("settings/1")
      .then((data) => {
        if (data?.data) {
          setSettingsData(data.data);
        } else {
          setSettingsData({});
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);
  return (
    <>
      <section className="about-us py-5">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-md-7 mb-3 mt-3">
              <div className="about-us-content ">
                <div className="title">
                  <h5>Explore the world with us, one adventure at a time.</h5>
                  <h1>
                    Welcome To <span> Minto Holidays </span> <br />
                    India' Best Tour Agency{" "}
                  </h1>
                </div>
                <div className="about-us-details mt-3">
                  <p>
                    Minto Holidays Is An Experienced Destination Management
                    Company Dealing In Domestic And International Sector By
                    Online And Offline. We Deals In Holidays Packages, Hotel
                    Booking, And Air Ticketing For Domestic (with in India)
                    Outbound (Out of India)&amp; Inbound (Foreigner arrive in
                    India) Sector.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eaque non optio porro excepturi blanditiis quod sequi ipsam
                    voluptatum voluptatem dolor officiis pariatur ex odit ea
                    quae velit unde omnis ab nam, et dolorum molestias ratione
                    in quia. Quis, provident cupiditate?
                  </p>
                  <p className="about-final-line">
                    <b>
                      Join us at Minto Holidays and discover the world in a way
                      you've never imagined. Let's make your next holiday truly
                      extraordinary!
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-3">
              <div className="about-us-page-img">
                <div className="main-img py-5">
                  <img
                    src="assets/images/domastic-destination/7.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="about-thumb-images">
                  <img
                    className="about-thum-1"
                    id="parallax-image"
                    src="assets/images/popular-packages/2.jpg"
                    alt=""
                    width="150px"
                  />
                  <img
                    className="about-thum-2"
                    id="parallax-image1"
                    src="assets/images/popular-packages/6.jpg"
                    alt=""
                    width="150px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about section end */}
      <div className="section-mission-vission py-5">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <div className="mission">
                <div className="mission-icon">
                  <img src="assets/images/icons/mission.png" alt="" />
                </div>
                <div className="title">
                  <h4 className="text-center">
                    {" "}
                    <span> Our Mission </span>
                  </h4>
                </div>
                <div className="mission-content mt-4">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: settingData?.our_mission,
                    }}
                  /> */}
                  <ReactMarkdown>{settingData?.our_mission}</ReactMarkdown>
                  {/* {settingData?.our_mission} */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-5">
              <div className="mission" id="vision-box">
                <div className="mission-icon">
                  <img src="assets/images/icons/vision.png" alt="" />
                </div>
                <div className="title">
                  <h4 className="text-center">
                    {" "}
                    <span> Our Vision </span>
                  </h4>
                </div>
                <div className="mission-content mt-4">
                {/* <div
                    dangerouslySetInnerHTML={{
                      __html: settingData?.our_vision,
                    }}
                  /> */}
                   <ReactMarkdown>{settingData?.our_vision}</ReactMarkdown>
                  {/* {settingData?.our_vision} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why Book Are Start  */}
      <section className="why-book py-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Why Book With Us</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="features">
                <div className="row">
                  {whyBook &&
                    whyBook?.length > 0 &&
                    whyBook?.map((wb) => {
                      return (
                        <div className="col-md-6 mb-3">
                          <div className="why-book-box box-1" id="box-1">
                            <div className="why-icon">
                              <img
                                src={`${Image_PATH}Whybook/${wb?.image_url}`}
                                alt=""
                              />
                            </div>
                            <div className="why-text">
                              <h4>{wb?.title}</h4>
                              <p>{wb?.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="col-md-6 mb-3">
                <div className="why-book-box box-2" id="box-2">
                  <div className="why-icon">
                    <img src="assets/images/icons/compititive-price.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Competitive Pricing</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-3">
                  <div className="why-icon">
                    <img src="assets/images/icons/calendar.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Fast Booking</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-4">
                  <div className="why-icon">
                    <img src="assets/images/icons/support.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Best Support 24/7</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-details">
                <span>Our Experience</span>
                <h3>{settingData?.exp_title}</h3>
                <p className="pb-3">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: settingData?.exp_description,
                    }}
                  /> */}
                  <ReactMarkdown>{settingData?.exp_description}</ReactMarkdown>
                  </p>
                <hr />
              </div>
              <div className="why-features row py-3">
                <div className="features-box col-4">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/traveler.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.happy_travellers}</h4>
                    <span>Happy Traveler</span>
                  </div>
                </div>
                <div className="features-box col-4" id="features-box-border">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/loyalty.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.tour_success}</h4>
                    <span>Tours Success</span>
                  </div>
                </div>
                <div className="features-box col-4">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/experience.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.positive_review}</h4>
                    <span>Positives Review </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="last-line">
                <p className="text-center">
                  <b>Excellent!</b>{" "}
                  <span>
                    {" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />
                    <i className="fa fa-circle-o" />{" "}
                  </span>{" "}
                  {settingData?.review_text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Book Are Start  */}
      {/* testimonial section start here  */}
      <section className="testimonials py-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Customers Feedback</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="slider mt-5">
            {testimonial && testimonial?.length > 0 && (
              <OwlCarousel
                options={options}
                className="owl-carousel owl-theme"
                id="owl-testimonial"
              >
                {testimonial &&
                  testimonial?.length > 0 &&
                  testimonial?.map((tm, k) => {
                    return (
                      <div className="item" key={k}>
                        <div className="review-box">
                          <div className="client-img">
                            <div className="user-img">
                              <div className="user-icon">
                                <img
                                  src={`${Image_PATH}testimonial/${tm?.image_url}`}
                                  alt=""
                                  style={{ width: 60 }}
                                />
                              </div>
                              <div className="user-name">
                                <h5>{tm?.name}</h5>
                                <span>{tm?.designation}</span>
                              </div>
                            </div>
                            <div className="stars py-2">
                              {tm?.star && +tm?.star === 1 && (
                                <i className="fa fa-star" />
                              )}
                              {tm?.star && +tm?.star === 2 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />{" "}
                                </>
                              )}
                              {tm?.star && +tm?.star === 3 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {tm?.star && +tm?.star === 4 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {tm?.star && +tm?.star === 5 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {/* <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" /> */}
                            </div>
                          </div>
                          <div className="review-para pt-2">
                            <p>{tm?.content}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div> */}
              </OwlCarousel>
            )}
          </div>
        </div>
      </section>
      {/* testimonial section end here     */}
      {/* floating-icons  */}
      <div className="quick_contact">
        <a
          href="https://api.whatsapp.com/send?phone=#&text=hello"
          className="qwhtsapp"
          target="_blank"
        >
          <i className="fa fa-whatsapp quick_contact_icon" />
        </a>
        <a href="#" className="qcall" target="_blank">
          <i className="fa fa-phone quick_contact_icon" />
        </a>
      </div>
      <div className="qiuck-enquiry">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Enquiry Now
        </button>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" id="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel" />
              <button
                type="button"
                className="btn-close modal-button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="row">
              <div className="col-lg-6" id="mobile-modal-hide">
                <div className="modal-body">
                  <div className="modal-banner-content">
                    <div className="modal-banner">
                      <div
                        id="carouselExampleRide"
                        className="carousel slide carousel-fade"
                        data-bs-ride="true"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <a href="#">
                              <img
                                src="assets/images/modal-banner/1.jpg"
                                className="d-block w-100"
                                alt="..."
                              />
                            </a>
                          </div>
                          <div className="carousel-item">
                            <a href="#">
                              <img
                                src="assets/images/modal-banner/2.jpg"
                                className="d-block w-100"
                                alt="..."
                              />
                            </a>
                          </div>
                          <div className="carousel-item">
                            <a href="#">
                              <img
                                src="assets/images/modal-banner/3.jpg"
                                className="d-block w-100"
                                alt="..."
                              />
                            </a>
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleRide"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleRide"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div className="modal-features">
                      <div className="why-features row py-3">
                        <div className="features-box col-4">
                          <div className="features-box-icon">
                            <img
                              src="assets/images/icons/traveler.png"
                              alt=""
                              width="45px"
                            />
                          </div>
                          <div className="features-box-name pl-2">
                            <h4>60K+</h4>
                            <span>Happy Traveler</span>
                          </div>
                        </div>
                        <div
                          className="features-box col-4"
                          id="features-box-border"
                        >
                          <div className="features-box-icon">
                            <img
                              src="assets/images/icons/loyalty.png"
                              alt=""
                              width="45px"
                            />
                          </div>
                          <div className="features-box-name pl-2">
                            <h4>60K+</h4>
                            <span>Tours Success</span>
                          </div>
                        </div>
                        <div className="features-box col-4">
                          <div className="features-box-icon">
                            <img
                              src="assets/images/icons/experience.png"
                              alt=""
                              width="45px"
                            />
                          </div>
                          <div className="features-box-name pl-2">
                            <h4>60K+</h4>
                            <span>Positives Review </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="last-line">
                        <p className="text-center">
                          <b>Excellent!</b>{" "}
                          <span>
                            {" "}
                            <i className="fa fa-circle" />{" "}
                            <i className="fa fa-circle" />{" "}
                            <i className="fa fa-circle" />{" "}
                            <i className="fa fa-circle" />{" "}
                            <i className="fa fa-circle-o" />{" "}
                          </span>{" "}
                          4.5 Rating Out of 5.0 Based On 526 reviews.
                        </p>
                      </div>
                    </div>
                    <div className="modal-contact">
                      <div className="call-modal">
                        <h6>
                          <a href="">
                            <span>
                              <i className="fa fa-phone" /> Call Us :{" "}
                            </span>{" "}
                            +91-1234567890
                          </a>
                        </h6>
                        <h6>
                          <a href="">
                            <span>
                              <i className="fa fa-envelope" /> Send Us Mail :{" "}
                            </span>{" "}
                            info@mintoholidays.com
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" id="pop-right">
                <div className="modal-body" id="right-body">
                  <div className="popup-form">
                    <h5>Get in touch with us</h5>
                    <form action="">
                      <div className="col mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="col mb-3">
                        <input
                          className="form-control"
                          type="tel"
                          placeholder="Enter Phone no."
                        />
                      </div>
                      <div className="col mb-3">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter Email Id."
                        />
                      </div>
                      <div className="col mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows={3}
                          placeholder="Enter Tour Details"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col- mb-3">
                        <button className="btn btn-submit">
                          Submit Enquiry
                        </button>
                        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                      </div>
                    </form>
                  </div>
                  <div className="get-whatsapp">
                    <a href="">
                      <span>
                        <img src="assets/images/icons/whatsapp.png" alt="" />
                      </span>
                      <h6>Get itenary On Whatsapp</h6>
                    </a>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
