/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
export default function Payment() {
  const [bank, setBank] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [settingData, setSettingsData] = useState({});
  useEffect(() => {
    getBankList();
    getMeta();
    getSettings();
  }, []);
  const getBankList = async () => {
    ApiService.post("bank/get-data", { page: 1, limit: 6, status: "1" })
      .then((data) => {
        if (data?.Banks) {
          setBank(data.Banks);
        } else {
          setBank([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "payment",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  const getSettings = async () => {
    ApiService.get("settings/1")
      .then((data) => {
        if (data?.data) {
          setSettingsData(data.data);
        } else {
          setSettingsData({});
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src="assets/images/payment-banner.jpg" alt="" width="100%" />
        </div>
      </section>
      {/* banner section end  */}
      <section className="contact py-5">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-md-12" style={{marginBottom:"10px"}}>
              <h3 className="">Scan For Pay</h3>
            </div>
            
            <div className="col-md-3 mb-3">
              <div className="payment-data">
                <div className="scaner">
                  <img
                    src={`${Image_PATH}Settings/${settingData?.qr}`}
                    alt=""
                    width="100%"
                  />
                  <div className="name" />
                </div>
                {/* <br></br>
                <div className="scaner">
                  <img
                    src={`${Image_PATH}Settings/${settingData?.qr2}`}
                    alt=""
                    width="40%"
                  />
                  <div className="name" />
                </div> */}
                {/* <div className="scanner-details">
                  <div className="holder-name ">
                    <h5 className="text-center">Minto Holidays Pvt. ltd.</h5>
                  </div>
                  <div className="payment-logo">
                    <img
                      src="assets/images/icons/paytm.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/phone-pay.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/g-pay.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/bhim-pay.png"
                      alt=""
                      width="70px"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="payment-data">
                {/* <div className="g-pay-title">
                  <h3 className="text-center">Scan For Pay</h3>
                </div> */}
                <div className="scaner">
                  <img
                    src={`${Image_PATH}Settings/${settingData?.qr2}`}
                    alt=""
                    width="100%"
                  />
                  <div className="name" />
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="payment-data">
                {/* <div className="g-pay-title">
                  <h3 className="text-center">Scan For Pay</h3>
                </div> */}
                <div className="scaner">
                  <img
                    src={`${Image_PATH}Settings/${settingData?.qr3}`}
                    alt=""
                    width="100%"
                  />
                  <div className="name" />
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="contact-page-form">
                <div className="title">
                  <h4 className="text-justify">Have A Secure Payment</h4>
                </div>
                <form className="mt-4" action="">
                  <div className="row">
                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-money" />
                      <input
                        type="text"
                        className="form-control"
                        id="Amount"
                        placeholder="Enter Amount *"
                      />
                    </div>
                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-user-o" />
                      <input
                        type="text"
                        className="form-control"
                        id="Name"
                        placeholder="Enter Full Name *"
                      />
                    </div>
                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-volume-control-phone" />
                      <input
                        type="tel"
                        className="form-control"
                        id="Phone"
                        placeholder="Enter Phone No. *"
                      />
                    </div>
                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-envelope-o" />
                      <input
                        type="email"
                        className="form-control"
                        id="Email"
                        placeholder="Enter Email Id *"
                      />
                    </div>
                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-flag-o" />
                      <input
                        type="tel"
                        className="form-control"
                        id="Country"
                        placeholder="Enter Address. *"
                      />
                    </div>
                  </div>
                  <div className="more-details-btn">
                    <button className="btn btn-more-pay">
                      <i className="fa fa-send-o" /> Pay Now
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="scanner-details">
                  <div className="holder-name ">
                    <h5 className="text-center">Minto Holidays Pvt. ltd.</h5>
                  </div>
                  <div className="payment-logo">
                    <img
                      src="assets/images/icons/paytm.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/phone-pay.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/g-pay.png"
                      alt=""
                      width="70px"
                    />
                    <img
                      src="assets/images/icons/bhim-pay.png"
                      alt=""
                      width="70px"
                    />
                  </div>
                </div>
                
          </div>
        </div>
      </section>
      <section className="bank-account-details py-3">
        <div className="container-fluid px-lg-5">
          <div className="title ">
            <h2>Bank Account Details</h2>
          </div>
          <div className="row mt-5 pt-2">
            {bank &&
              bank?.length > 0 &&
              bank?.map((bk) => {
                return (
                  <div className="col-md-4 mb-5">
                    <div className="bank-details-box">
                      <div className="bank-logo">
                        <img
                          src={`${Image_PATH}Bank/${bk?.image_url}`}
                          alt=""
                          width="200px"
                        />
                      </div>
                      <div className="account-details mt-4">
                        <div className="accounts-title">
                          <h5 className="text-center">Account Details</h5>
                          <div className="account-table">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="bold">Payee Account No:</td>
                                  <td>{bk?.account_no}</td>
                                </tr>
                                <tr>
                                  <td className="bold">Payee Name :</td>
                                  <td>{bk?.name}</td>
                                </tr>
                                <tr>
                                  <td className="bold">IFSC Code :</td>
                                  <td>{bk?.ifsc_code}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-md-4 mb-5">
          <div className="bank-details-box">
            <div className="bank-logo">
              <img src="assets/images/icons/icici.png" alt="" width="200px" />
            </div>
            <div className="account-details mt-4">
              <div className="accounts-title">
                <h5 className="text-center">Account Details</h5>
                <div className="account-table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="bold">Payee Account No:</td>
                        <td>1234567890123</td>
                      </tr>
                      <tr>
                        <td className="bold">Payee Name :</td>
                        <td>Minto Holidays Pvt. Ltd.</td>
                      </tr>
                      <tr>
                        <td className="bold">IFSC Code :</td>
                        <td>ICIC0001111</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <div className="bank-details-box">
            <div className="bank-logo">
              <img src="assets/images/icons/sbi.png" alt="" width="200px" />
            </div>
            <div className="account-details mt-4">
              <div className="accounts-title">
                <h5 className="text-center">Account Details</h5>
                <div className="account-table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="bold">Payee Account No:</td>
                        <td>1234567890123</td>
                      </tr>
                      <tr>
                        <td className="bold">Payee Name :</td>
                        <td>Minto Holidays Pvt. Ltd.</td>
                      </tr>
                      <tr>
                        <td className="bold">IFSC Code :</td>
                        <td>ICIC0001111</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
